<template>
  <div>
    <v-input v-model="form.email" label="E-Mail" :show-errors="showErrors" :disabled="disabled"/>
    <v-input v-model="form.firstName" label="Voornaam" :show-errors="showErrors" :disabled="disabled"/>
    <v-input v-model="form.lastName" label="Achternaam" :show-errors="showErrors" :disabled="disabled"/>
    <v-input v-model="form.postalCode" label="Postcode" :show-errors="showErrors" :disabled="disabled"/>
    <v-input v-model="form.city" label="Stad" :show-errors="showErrors" :disabled="disabled"/>
    <v-input v-model="form.street" label="Straat" :show-errors="showErrors" :disabled="disabled"/>
    <v-input v-model="form.houseNumber" label="Huisnummer" :show-errors="showErrors" :disabled="disabled"/>
    <v-validate-button :disabled="disabled"
                       :form="form"
                       @valid="valid"
                       @error="setError"/>
  </div>
</template>

<script>
import baseForm from "@/components/shared/baseForm"
import validator from './user.validator'

export default {
  extends: baseForm,
  data() {
    return {
      form: validator,
      validations: validator,
    }
  }
}
</script>