<template>
  <div>
    <formUser :data="forms" @valid="editUser" :disabled="disabled" />
  </div>
</template>

<script>
import formUser from './formUser'

export default {
  components: {
    formUser
  },
  props: {
    userId: {
      required: true
    },
    disabled: {
      default: true
    }
  },
  data() {
    return {
      forms: null
    }
  },
  created() {
    this.getUser(this.userId)
  },
  methods: {
    async getUser(id) {
      let {data} = await this.$api.user.get(id)
      this.forms = {
        email: data.email,
        firstName: data.first_name,
        lastName: data.last_name,
        street: data.street,
        houseNumber: data.house_number
      }
    },
    async editUser(form){
      console.log(form)
    }
  }
}
</script>