import {required} from "@/utils/validator";

export default {
    email: {
        validations: {
            required
        }
    },
    firstName: {
        validations: {
            required
        }
    },
    lastName: {
        validations: {
            required
        }
    },
    city: {
        validations: {
            required
        }
    },
    postalCode: {
        validations: {
            required
        }
    },
    street: {
        validations: {
            required
        }
    },
    houseNumber: {
        validations: {
            required
        }
    }
}