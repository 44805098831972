<template>
  <d-container fluid>
    <pageTitle title="Gebruikers Overzicht"
               :back="true"/>
    <d-row>
      <vCard :col="8" header="Personalia" :actions="cardActions">
        <editUser :user-id="$route.params.user_id" :disabled="isDisabled"/>
      </vCard>
      <vCard :col="4" header="Gebruikers rollen">
        <d-col>
          <d-checkbox v-for="(key, role) in roles" :key="key" name="fav_fruits" :value="role" v-model="enabledRoles">
            {{ role | roleFormatter }}
          </d-checkbox>
        </d-col>
      </vCard>
    </d-row>
  </d-container>
</template>

<script>
import editUser from "@/components/forms/user/editUser"

export default {
  name: 'userOverview',
  components: {
    editUser
  },
  created() {
    this.getRoles()
    this.getUser()
  },
  data() {
    return {
      roles: [],
      enabledRoles: [],
      form: {
        birthDate: null,
        country: null,
        email: null,
        employedAt: null,
        firstName: null,
        gender: null,
        houseNumber: null,
        lastName: null,
        postalCode: null,
        roles: ["ROLE_USER"],
        street: null,
        userFunctions: []
      },
      cardActions: [
        {
          fn: () => {
            this.isDisabled = !this.isDisabled
          }, icon: `<i class='material-icons'>edit</i>`
        }
      ],
      isDisabled: true
    }
  },
  methods: {
    async getUser() {
      // let {data} = await this.$api.user.get(this.$route.params.user_id)
      // console.log(data)
      // this.form = this.$v.inject(validations, {
      //   firstName: data.first_name,
      //   lastName: data.last_name,
      //   gender: data.gender,
      //   houseNumber: data.house_number,
      //   street: data.street,
      //   postalCode: data.postal_code
      // })
    },
    async getRoles() {
      let response = await this.$api.role.list()
      this.roles = response.data
    }
  }
}
</script>
